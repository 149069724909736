// LIBRARIES
import { createGlobalStyle } from "styled-components";
import styled from 'styled-components'
// STYLES
import { Breakpoints, Pad } from "./breakpoints";

export const GlobalStyle = createGlobalStyle`
* {
  padding:0;
  margin:0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: ${(props) => props.theme.content};
  color: ${(props) => props.theme.background};
}

html, body {
  height:100%;
  scroll-behavior: smooth;
  font-family: 'Avantt', sans-serif;
}

button {
  font-family: 'Avantt', sans-serif;
}

body {
  min-height:100vh;
  background-color:${(props) => props.theme.background};
  background-size: cover;
  font-weight:300;
  position: relative;
  color:${(props) => props.theme.content};
  overflow-x: hidden;
  transition:background 200ms;
}

img { transition: all 600ms; }

.move-in {
  opacity: 0;
  transform: translateY(10vh);
  transition: all 500ms;
}

section {
  opacity:0;
  transition: opacity 450ms ease-in;
  transition-delay: opacity 450ms;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { padding: 0 ${Pad.mobile} }
  ${Breakpoints.medium} { padding: 0 ${Pad.medium} }
  ${Breakpoints.tablet} { padding: 0 ${Pad.tablet} }
  ${Breakpoints.smDesktop} { padding: 0 ${Pad.smDesktop} }
  ${Breakpoints.desktop} { padding: 0 ${Pad.desktop} }
  ${Breakpoints.wide} { padding: 0 ${Pad.wide} }
}

.device-padding {
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { padding: 0 ${Pad.mobile} }
  ${Breakpoints.medium} { padding: 0 ${Pad.medium} }
  ${Breakpoints.tablet} { padding: 0 ${Pad.tablet} }
  ${Breakpoints.smDesktop} { padding: 0 ${Pad.smDesktop} }
  ${Breakpoints.desktop} { padding: 0 ${Pad.desktop} }
  ${Breakpoints.wide} { padding: 0 ${Pad.wide} }
}
`

export const AppComponent = styled.div``
