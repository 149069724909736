// TOOLS
// STYLES
import { WorkStyle, Thumbnail } from "./style";
// COMPONENTS
import ProjectLink from "./menu";
// CONTENT
import ProjectList from '../../content/work.json'
// LANGUAGE
import { useContext } from 'react';
import Language from '../../content/language';
// COMPONENTS
import Footer from "../footer";

const WorkComponent = () => {

  // Get Content
  const context = useContext(Language);
  const content = context.language.projects

  const List = Object.values(ProjectList).map( (project, i) => {
  
    // Get Project content
  const projectTags = Object.values(content)[i].tags
  const tags = Object.values(projectTags).map( (tag, i) => tag ).join(' / ')

  const name = project.name
  const path = project.path
  const infos = project.infos

  return <ProjectLink number={i} name={name} path={path} tags={tags} infos={infos} key={i} />

  })

  const lastNumber = Object.values(ProjectList).length

  return (
    <>
      <WorkStyle.Container aria-label="work page">
        {List}
        <ProjectLink name='playground' path='/playground'  tags='' infos='' number={lastNumber} key={lastNumber} />
        <Thumbnail.Container className='thumbnail'>
          <Thumbnail.Image className="project-thumbnail"/>
        </Thumbnail.Container>
      </WorkStyle.Container>
      <Footer />
    </>
)}

export default WorkComponent