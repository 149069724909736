// LIBRARIES
import { useLocation } from "react-router-dom"
// STYLES
import { NavStyles } from "./style"
// COMPONENTS
import ThemeSwitch from "./theme"
import LanguageSwitch from "./lang"

const Nav = ({ changeTheme, ColorTheme, setColorTheme }) => {
  const location = useLocation().pathname
  const navLogo = location === '/' ? 'hidden' : ''
  
  return (
  <NavStyles.Container className='device-padding'>
    <NavStyles.Menu to='/' className='page-link'>
      <NavStyles.NavLogo className={navLogo} aria-label="home"/>
    </NavStyles.Menu>
    <NavStyles.Settings>
      <ThemeSwitch
        changeTheme={changeTheme}
        ColorTheme={ColorTheme}
        setColorTheme={setColorTheme}
        aria-label="set theme"/>
      <LanguageSwitch aria-label="set language"/>
    </NavStyles.Settings>
  </NavStyles.Container>
)}

export default Nav