// TOOLS
// STYLES
import styled from 'styled-components'
import { Breakpoints } from '../../../styles/breakpoints'
import { ProjectStyle } from '../style'
import { Headings } from '../../../styles/type'
import { Arrow } from '../../../styles/vectors'

export const PlaygroundGallery= styled(ProjectStyle.Gallery)`
  margin:10vh 5vw 20vh 0;
  gap:3vw;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} {margin:5vh 0 25vh 0;align-items:initial; gap: 0;}
  ${Breakpoints.medium} {margin:5vh 0 20vh 0;align-items:initial; gap: 0;}
  ${Breakpoints.tablet} {gap:0; margin: 10vh 0 20vh;}
  ${Breakpoints.smDesktop} { margin: 10vh 0 20vh; }
`
export const PlaygroundInfo = styled(ProjectStyle.Details)`
  flex:2;
  top: 35vh;
  display: flex;
  flex-flow:column;
  justify-content: flex-start;
  align-items: flex-start;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} {
    position:sticky;
    top: 0;
    z-index:5;
    flex:initial;
    height: 35vh;
    justify-content:center;
    padding:0;
    background: ${(props)=> props.theme.background};
    /* width: 100vw; */
  }
  ${Breakpoints.medium} {
    position:sticky;
    top: 0;
    z-index:5;
    padding:14vh 0 6vh;
    background: ${(props)=> props.theme.background};
    width: 100vw;
  }
  ${Breakpoints.tablet} {flex:1.5;}
  ${Breakpoints.smDesktop} {flex:1.5; }
`

export const PlaygroundStyle = {
  Container: styled.div`
    flex:4;
    display:flex;
    flex-flow:column;
    gap:12vh;
    padding: 0 0 5vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { gap:12vh; padding:0 0 12vh; }
    ${Breakpoints.medium} { gap:5vh; }
    ${Breakpoints.tablet} {gap:10vh;flex:2.5;}
    ${Breakpoints.smDesktop} {gap:10vh;flex:2.5;}
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit:cover;
    opacity:0;
    transition: transform 400ms, opacity 600ms ease-in;
  `,
  Number: styled(Headings.Heading2)`
    line-height: 1.25;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:2rem; letter-spacing:-0.05rem; }
    ${Breakpoints.medium} { font-size:2rem; letter-spacing:-0.05rem; }
    ${Breakpoints.tablet} { font-size: 3rem; letter-spacing:-0.15rem;} 
  `,
  Title: styled(Headings.Heading1)`
    text-transform: uppercase;
    line-height:0.85;
    margin:0 5rem 1.5rem 0;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:8vw; letter-spacing:-0.05rem; margin:0.5rem 0;}
    ${Breakpoints.medium} { font-size:2.25rem; letter-spacing:-0.05rem; margin:0.5rem 0;}
    ${Breakpoints.tablet} { font-size: 2.5rem; letter-spacing:-0.15rem; font-weight:600;}
    ${Breakpoints.smDesktop} { font-size: 2.5rem; letter-spacing:-0.15rem; }
  `,
  Tools: styled.div`
    display: flex;
    gap:0.5rem;
    p {
      text-transform: uppercase;
      font-weight:500;
      letter-spacing: -0.02rem;
      font-size:1rem;
      line-height:1.25;
      color: ${(props)=> props.theme.content};
    }
  `,
  Link: styled.a`
    margin: 3rem 0 0 !important;
    color: ${(props)=> props.theme.project.link};
    font-size:1.25rem;
    letter-spacing:-0.05rem;
    font-weight:700;
    text-decoration: none;
    text-transform: uppercase;
    display:flex;
    align-items:center;
    gap:0.5rem;
    transition: all 200ms;
    ${Arrow} { transition: all 200ms;
      g {stroke:${(props)=> props.theme.project.link}; stroke-width: 2.5px;}
      height: 100%;
    }
    &:hover {
      color: ${(props)=> props.theme.project.linkHover};
      ${Arrow} { transform: translateX(0.75rem);
        g {stroke:${(props)=> props.theme.project.linkHover}; }
      }
    }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { margin: 1rem 0 0 !important; }
    ${Breakpoints.medium} { margin: 1rem 0 0 !important; }
    ${Breakpoints.tablet} { margin:2rem 0 0 !important; }
  `
}