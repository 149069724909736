// LANGUAGE
import Language from '../../../content/language';
// TOOLS
import{ useContext } from 'react'
import { Themes } from "../../../styles/colors";
// STYLES
import { ModeSwitch } from "./style"


const ThemeSwitch = ({ changeTheme, ColorTheme, setColorTheme }) => {
  // Get Content 
  const context = useContext(Language);
  const content = context.language.nav


  return (
    <ModeSwitch.Container>
      <ModeSwitch.Setting
        type="button"
        onClick={() => {
          changeTheme(ColorTheme)
          setColorTheme(Themes.Red)
        }}
        aria-label="red theme">
          {content.red}
      </ModeSwitch.Setting>
      <ModeSwitch.Line>|</ModeSwitch.Line>
      <ModeSwitch.Setting
        type="button"
        onClick={() => {
          changeTheme(ColorTheme)
          setColorTheme(Themes.White)
        }}
        aria-label="white theme">
          {content.white}
      </ModeSwitch.Setting>
      <ModeSwitch.Line>|</ModeSwitch.Line>
      <ModeSwitch.Setting
        type="button"
        onClick={() => {
          changeTheme(ColorTheme)
          setColorTheme(Themes.Black)
        }}
        aria-label="black theme">
          {content.black}
        </ModeSwitch.Setting>
    </ModeSwitch.Container>
  )
}

export default ThemeSwitch