// LIBRARY
import styled from 'styled-components'
// COMPONENTS
import HomeComponent from "../components/home"

const Homepage = styled.section` height:100vh; `

const Home = () => (
  <Homepage>
    <HomeComponent />
  </Homepage>
)

export default Home