// TOOLS
import{ useContext, useState } from 'react'
import Content from '../../../content/content.json'
import Language from '../../../content/language'
// STYLES
import { LangSwitch } from "./style"


const LanguageSwitch = () => {


  // english version of the content
  const EN = Content.EN
  // French version of the content
  const FR = Content.FR

  // Set State for Language from Context
  const { language, setLanguage } = useContext(Language);

    // Set language to French
    const frenchLang = () => {
      setLanguage(FR)
      document.documentElement.setAttribute("lang", 'fr');
    }
    // Set language to english
    const englishLang = () => {
      setLanguage(EN)
      document.documentElement.setAttribute("lang", 'en');
    }

  return (
    <LangSwitch.Container>
      <LangSwitch.Setting onClick={englishLang} aria-label="English">EN</LangSwitch.Setting>
        <LangSwitch.Line>|</LangSwitch.Line>
      <LangSwitch.Setting onClick={frenchLang} aria-label="French">FR</LangSwitch.Setting>
    </LangSwitch.Container>
  )
}

export default LanguageSwitch