import { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
// Pages
import Home from '../pages/home.jsx'
import Work from '../pages/work.jsx'
import About from '../pages/about.jsx'
import Lost from "../pages/404.jsx"
// Projects
import Project from '../pages/project'
import Projects from '../content/work.json' 
import Playground from "../components/project/playground/index.jsx"
// -----------
import { reveal, fadeLogo, showThumbnail, getPlay, setLazy, globalTransitions } from '../styles/transitions'

const Routing = () => {
  const location = useLocation();
  useEffect(() => {
    const nav = document.querySelector('nav')
    const section = document.querySelector('section')
    nav.style.opacity = 1
    section.style.opacity = 1
    // Scroll to top on any new page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    // Project transitions
    setLazy()
    globalTransitions()
    reveal()
    fadeLogo(location)
    showThumbnail(location)
    getPlay(location)
  }, [location])
  
  const createRoutes = Object.values(Projects).map((project, i) => {
      const path = project.path
      const index = i
      return <Route key={index} path={path} element={<Project index={index} name={project.name} path={path}/>} />
    })

  
  return (
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/work' element={<Work />} />
    <Route path='/about' element={<About />} />
    {createRoutes}
    <Route path='/playground' element={<Playground />} />
    <Route path='/404' element={<Lost />} />
  </Routes>
)}

export default Routing