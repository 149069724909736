import styled from 'styled-components'

/** PROJECTS LOGOS */
import { ReactComponent as Logo1Src } from './project1/fide.svg'  
import { ReactComponent as Logo2Src } from './project2/psychotrope.svg'  
import { ReactComponent as Logo3Src } from './project3/logo.svg'
import { ReactComponent as Logo4Src } from './project4/viagate.svg'
import { ReactComponent as Logo5Src } from './project5/pseudo.svg' 
import { ReactComponent as Logo6Src } from './project6/regent.svg'
import { ReactComponent as Logo7Src } from './project7/two-tugs.svg'
import { ReactComponent as Logo8Src } from './playground/playground.svg'

/** PROJECTS THUMBNAILS */
export const Thumbnails = {
  "0":require('./project1/fide-thumbnail.jpg'),
  "1":require('./project2/psychotrope-thumbnail.jpg'),
  "2":require('./project3/tease-thumbnail-2.jpg'),
  "3":require('./project4/viagate-thumbnail.jpg'),
  "4":require('./project5/pseudo-thumbnail.jpg'),
  "5":require('./project6/regent-thumbnail.jpg'),
  "6":require('./project7/twotugs-thumbnail.jpg'),
  "7":require('./playground/thumbnail.jpg'),
} 

/** ******************************************* */

export const ProjectLogos = {
  Logo1: styled(Logo1Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo2: styled(Logo2Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo3: styled(Logo3Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo4: styled(Logo4Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo5: styled(Logo5Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo6: styled(Logo6Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo7: styled(Logo7Src)`g{fill: ${(props) => props.theme.project.logo}}`,
  Logo8: styled(Logo8Src)`g{fill: ${(props) => props.theme.project.logo}}`,
}
/** ******************************************* */

/** PROJECT GALLERIES - INDEX */
export const Gallery = {
  "0" : {
    img1: {
      src: require('./project1/hero.jpg'),
      alt: "fide brochure cover design"},
    img2: {
      src: require('./project1/covers_pile.jpg'),
      alt: "fide brochure design"},
    img3: {
      src: require('./project1/open_cover.jpg'),
      alt: "fide brochure spread design"},
    img4: {
      src: require('./project1/spread_1.jpg'),
      alt: "fide spread editorial layout design"},
    img5: {
      src: require('./project1/gallery_spread_1.jpg'),
      alt: "fide spread gallery layout design"},
    img6: {
      src: require('./project1/spread_2.jpg'),
      alt: "fide spread editorial layout design"},
    img7: {
      src: require('./project1/gallery_spread_2.jpg'),
      alt: "fide spread gallery layout design"},
    img8: {
      src: require('./project1/posters.jpg'),
      alt: "fide poster design mockup"},
    img9: {
      src: require('./project1/shirts.jpg'),
      alt: "fide merch shirt design mockup"},
    img10: {
      src: require('./project1/posters_2.jpg'),
      alt: "fide poster design mockup"},
    img11: {
      src: require('./project1/tote.jpg'),
      alt: "fide merch bag design mockup"}
  },
  "1" : {
    img1: {
      src: require('./project2/hero.jpg'),
      alt: "psychotrope album cd album cover design"},
    img2: {
      src: require('./project2/illustration_1.jpg'),
      alt: "psychotrope album illustration design"},
    img3: {
      src: require('./project2/booklet_cover.jpg'),
      alt: "psychotrope album booklet cover design"},
    img4: {
      src: require('./project2/vinyl_front.jpg'),
      alt: "psychotrope album vinyl cover design"},
    img5: {
      src: require('./project2/illustration_2.jpg'),
      alt: "psychotrope album illustration design"},
    img6: {
      src: require('./project2/booklet_spread_1.jpg'),
      alt: "psychotrope album booklet spread layout illustration design"},
    img7: {
      src: require('./project2/open_vinyl.jpg'),
      alt: "psychotrope album vinyl inside sleeve cover design"},
    img8: {
      src: require('./project2/booklet_spread_2.jpg'),
      alt: "psychotrope album booklet spread layout illustration design"},
    img9: {
      src: require('./project2/illustration_3.jpg'),
      alt: "psychotrope album illustration design"},
    img10: {
      src: require('./project2/vinyl_back.jpg'),
      alt: "psychotrope album vinyl back cover design"},
    img11: {
      src: require('./project2/illustration_4.jpg'),
      alt: "psychotrope album illustration design"}
  },
  "2" : {
    img1: {
      src: require('./project3/home-header.jpg'),
      alt: "tease web site home header design"},
    img2: {
      src: require('./project3/home-text.jpg'),
      alt: "tease web site home layout design"},
    img3: {
      src: require('./project3/home-producers.jpg'),
      alt: "tease web site home masonry section design"},
    img4: {
      src: require('./project3/shop.jpg'),
      alt: "tease web site shop header design"},
    img5: {
      src: require('./project3/shop-items.jpg'),
      alt: "tease web site shop products design"}
  },
  "3" : {
    img1: {
      src: require('./project4/header.jpg'),
      alt: "viagate web site home header design"},
    img2: {
      src: require('./project4/home-intro.jpg'),
      alt: "viagate web site page layout design"},
    img3: {
      src: require('./project4/home-expert.jpg'),
      alt: "viagate web site page expertise design"},
    img4: {
      src: require('./project4/expert.jpg'),
      alt: "viagate web site expertise layout design"},
    img5: {
      src: require('./project4/expert-bridges.jpg'),
      alt: "viagate web site expertise grid section design"},
    img6: {
      src: require('./project4/about.jpg'),
      alt: "viagate web site about layout design"},
    img7: {
      src: require('./project4/team.jpg'),
      alt: "viagate web site about team grid layout design"},
    img8: {
      src: require('./project4/projects.jpg'),
      alt: "viagate web site projects grid layout design"},
    img9: {
      src: require('./project4/blog-page.jpg'),
      alt: "viagate web site blog grid layout design"},
    img10: {
      src: require('./project4/blog-article.jpg'),
      alt: "viagate web site blog post design"},
    img11: {
      src: require('./project4/contact.jpg'),
      alt: "viagate web site contact page design"}
  },
  "4" : {
    img1: {
      src: require('./project5/brand.jpg'),
      alt: "pseudo-design web site contact page design"},
    img2: {
      src: require('./project5/full-cover.jpg'),
      alt: "pseudo-design web site contact page design"},
    img3: {
      src: require('./project5/spread.jpg'),
      alt: "pseudo-design web site contact page design"},
    img4: {
      src: require('./project5/header.jpg'),
      alt: "pseudo-design web site home header design"},
    img5: {
      src: require('./project5/home-services.jpg'),
      alt: "pseudo-design web site home service design"},
    img6: {
      src: require('./project5/banner.jpg'),
      alt: "pseudo-design web site banner design"},
    img7: {
      src: require('./project5/projects.jpg'),
      alt: "pseudo-design web site projects layout design"},
    img8: {
      src: require('./project5/reviews.jpg'),
      alt: "pseudo-design web site projects reviews design"},
    img9: {
      src: require('./project5/services.jpg'),
      alt: "pseudo-design web site services header design"},
    img10: {
      src: require('./project5/services-infos.jpg'),
      alt: "pseudo-design web site services infos layout design"},
    img11: {
      src: require('./project5/process.jpg'),
      alt: "pseudo-design web site services q&a design"},
    img12: {
      src: require('./project5/contact.jpg'),
      alt: "pseudo-design web site contact page design"},

  },
  "5" : {
    img1: {
      src: require('./project6/header.jpg'),
      alt: "regent web site home header design"},
    img2: {
      src: require('./project6/profile.jpg'),
      alt: "regent web site home profile design"},
    img3: {
      src: require('./project6/gallery.jpg'),
      alt: "regent web site home gallery layout design"},
    img4: {
      src: require('./project6/services.jpg'),
      alt: "regent web site services layout design"},
    img5: {
      src: require('./project6/reviews.jpg'),
      alt: "regent web site reviews page header design"},
    img6: {
      src: require('./project6/blog.jpg'),
      alt: "regent web site blog layout design"}
  },
  "6" : {
    img1: {
      src: require('./project7/bottles.jpg'),
      alt: "two-tugs bottles packaging design"},
    img2: {
      src: require('./project7/header.jpg'),
      alt: "two-tugs web site home header design"},
    img3: {
      src: require('./project7/range.jpg'),
      alt: "two-tugs web site beer range design"},
    img4: {
      src: require('./project7/brewery.jpg'),
      alt: "two-tugs web site brewery section design"},
    img5: {
      src: require('./project7/island.jpg'),
      alt: "two-tugs web site vancouver section design"},
    img6: {
      src: require('./project7/beers.jpg'),
      alt: "two-tugs web site beer page design"},
    img7: {
      src: require('./project7/about.jpg'),
      alt: "two-tugs web site about page design"},
    img8: {
      src: require('./project7/community.jpg'),
      alt: "two-tugs web site about community section design"},
    img9: {
      src: require('./project7/team.jpg'),
      alt: "two-tugs web site about team design"},
    img10: {
      src: require('./project7/locations.jpg'),
      alt: "two-tugs web site locations design"}
  }
}
/** ******************************************* */

/** PLAYGROUND THUMBNAILS */
export const PlayGallery = {
  "0": require("./playground/super-shapes.png"),
  "1": require("./playground/bigbig.png"),
  "2": require("./playground/brux.png"),
  "3": require("./playground/typechecker.png"),
  "4": require("./playground/collage.png"),
  "5": require("./playground/4th-kind.png"),
  "6": require("./playground/conf.png")
}
/** ******************************************* */

/** PROJECTS MOBILE GALLERIES - INDEX */
export const MobileGallery = {
  "0" : {
    mobile1:{
      src:'',
      alt:''
    },
    mobile2:{
      src:'',
      alt:''
    },
    mobile3:{
      src:'',
      alt:''
    }
  },
  "1" : {
    mobile1:{
      src:'',
      alt:''
    },
    mobile2:{
      src:'',
      alt:''
    },
    mobile3:{
      src:'',
      alt:''
    }
  },
  "2" : {
    mobile1:{ 
      src: require('./project3/mobile1.png'),
      alt: "tease mobile home"},
    mobile2:{
      src: require('./project3/mobile2.png'),
      alt:"tease mobile producers"},
    mobile3:{
      src: require('./project3/mobile3.png'),
      alt:"tease mobile shop"},
  },
  "3" : {
    mobile1:{
      src: require('./project4/mobile1.png'),
      alt:"viagate mobile home"},
    mobile2:{
      src: require('./project4/mobile2.png'),
      alt:"viagate mobile menu"},
    mobile3:{
      src: require('./project4/mobile3.png'),
      alt:"viagate mobile blog"},
  },
  "4" : {
    mobile1:{
      src: require('./project5/mobile1.png'),
      alt:"pseudo-design mobile home"},
    mobile2:{
      src: require('./project5/mobile2.png'),
      alt:"pseudo-design mobile services"},
    mobile3:{
      src: require('./project5/mobile3.png'),
      alt:"pseudo-design mobile contacts"},
  },
  "5" : {
    mobile1:{
      src: require('./project6/mobile1.png'),
      alt:"regent mobile home"},
    mobile2:{
      src: require('./project6/mobile2.png'),
      alt:"regent mobile contact"},
    mobile3:{
      src: require('./project6/mobile3.png'),
      alt:"regent mobile blog"}
  },
  "6" : {
    mobile1:{
      src: require('./project7/mobile1.png'),
      alt: "two-tugs mobile home"},
    mobile2:{
      src: require('./project7/mobile2.png'),
      alt:"two-tugs mobile products"},
    mobile3:{
      src: require('./project7/mobile3.png'),
      alt:"two-tugs mobile vendors"}
  },
  "7" : {
    mobile1:{
      src:'',
      alt:''
    },
    mobile2:{
      src:'',
      alt:''
    },
    mobile3:{
      src:'',
      alt:''
    }
  }
}
/** ******************************************* */




