// LIBRARIES
import styled from 'styled-components'
// STYLES
import { Breakpoints } from '../../styles/breakpoints'
// COMPONENTS
import { Logo } from '../../styles/vectors'
import { Link } from 'react-router-dom'

export const NavStyles = {
  Container: styled.nav`
    opacity: 0;
    transition: opacity 450ms;
    width: 100vw;
    height: 6vh;
    display:flex;
    align-items:center;
    justify-content: space-between;
    position:fixed;
    left:0;
    top:0;
    z-index:10;
    &.hidden { display:none; }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {}
  `,
  Menu: styled(Link)``,
  NavLogo: styled(Logo)`
    width: 2vw;
    height: auto;
    transition: all 200ms;
    g {fill: ${(props) => props.theme.nav.link} }
    &:hover { g {fill: ${(props) => props.theme.nav.linkHover}} }
    &.hidden { display:none; }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { width: 10vw; }
    ${Breakpoints.medium} { width: 5vw; }
    ${Breakpoints.tablet} { width: 3vw; }
  `,
  Settings: styled.div`
    width: 20vw;
    display:flex;
    align-items:center;
    justify-content: space-between;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
     width: 70vw;
     justify-content: space-between;
    }
    ${Breakpoints.medium} { width: 60vw; }
    ${Breakpoints.tablet} { width: 50vw; }
  `
}