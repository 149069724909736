// LIBRARIES
import styled from 'styled-components'
// STYLES
import { Breakpoints } from '../../../styles/breakpoints'
// COMPONENTS


export const ModeSwitch = {
  Container: styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap:0.5rem;
    color:${props => props.theme.nav.link};
  `,
  Setting: styled.button`
    font-size:0.95rem;
    text-transform: capitalize;
    font-weight:400;
    transition: all 200ms;
    cursor:pointer;
    border:none;
    background:none;
    color:${props => props.theme.nav.link};
    font-family: 'Avantt', sans-serif;
    &:hover,
    &:focus {color: ${props => props.theme.nav.linkHover}}
  `,
  Line: styled.p``
}