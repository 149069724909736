// LIBRARIES
// STYLES
import { ProjectLinkStyle } from "./style";
// COMPONENTS


const ProjectLink = (props) => {

  const key = props.number + 1
  const count = () => {
    if(key<10) return '0' + key 
    else return key
  }

  return (
  <ProjectLinkStyle.Container to={props.path} className='page-link move-in'>
    <ProjectLinkStyle.Name className='project-link'>{props.name}</ProjectLinkStyle.Name> 
    <ProjectLinkStyle.Infos>
      <ProjectLinkStyle.Number>//{count()}</ProjectLinkStyle.Number>
      <ProjectLinkStyle.Details>
        <ProjectLinkStyle.Tags>{props.tags}</ProjectLinkStyle.Tags>
        <ProjectLinkStyle.Client>{props.infos}</ProjectLinkStyle.Client>
      </ProjectLinkStyle.Details>
    </ProjectLinkStyle.Infos>
  </ProjectLinkStyle.Container>
)}

export default ProjectLink
