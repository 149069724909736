// LIBRARY
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils'
import styled from 'styled-components'
// COMPONENTS
import Nav from '../components/nav'
import WorkComponent from '../components/work'
import Project from './project'

const Workpage = styled.section`
  height:100vh;
`

const Work = () => (
  <Workpage>
    <WorkComponent />
  </Workpage>
)

export default Work