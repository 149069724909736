
/* COLOR VALUES */
// const RED = '#DA5002'
// const RED = '#E55721'
const RED = '#E14C2C'
const LIGHT = '#E1E7CD'
// const BLACK = '#110F0C'
const BLACK = '#0D0B05'
const GREY = '#786F5F'

/* THEMES */
export const Themes = {
  Red: {
    theme:'red',
    background: RED,
    content: BLACK,
    nav: {
      link: BLACK,
      linkHover: LIGHT
    },
    home: {
      brand: LIGHT,
      text: BLACK,
      link: LIGHT,
      linkHover: BLACK,
      contact: LIGHT
    },
    work: {
      project:LIGHT,
      projectHover:BLACK,
      projectInfos:BLACK
    },
    about: {
      heading:LIGHT,
      content:BLACK,
      sub_content: BLACK
    },
    project: {
      logo:BLACK,
      text: BLACK,
      hover: LIGHT,
      link: BLACK,
      linkHover: LIGHT,
      tools: GREY
    },
    misc: {
      text: LIGHT,
      eyes: BLACK,
      button: BLACK
    }
  },
  White: {
    theme:'light',
    background: LIGHT,
    content: BLACK,
    nav: {
      link: BLACK,
      linkHover: RED
    },
    home: {
      brand: BLACK,
      text: GREY,
      link: BLACK,
      linkHover: RED,
      contact: GREY
    },
    work: {
      project:BLACK,
      projectHover:RED,
      projectInfos:RED
    },
    about: {
      heading:BLACK,
      content:BLACK,
      sub_content: GREY
    },
    project: {
      logo:BLACK,
      text: BLACK,
      hover: RED,
      link: BLACK,
      linkHover: RED,
      tools: GREY
    },
    misc: {
      text: RED,
      eyes: BLACK,
      button: BLACK
    }
  },
  Black: {
    theme:'black',
    background: BLACK,
    content: LIGHT,
    nav: {
      link: LIGHT,
      linkHover: RED
    },
    home: {
      brand: LIGHT,
      text: GREY,
      link: LIGHT,
      linkHover: RED,
      contact: GREY
    },
    work: {
      project:LIGHT,
      projectHover:RED,
      projectInfos: GREY
    },
    about: {
      heading:LIGHT,
      content:LIGHT,
      sub_content: GREY
    },
    project: {
      logo:LIGHT,
      text: LIGHT,
      hover: RED,
      link: LIGHT,
      linkHover: RED,
      tools: GREY
    },
    misc: {
      text: LIGHT,
      eyes: RED,
      button: RED
    }
  }
}

