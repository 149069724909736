// LIBRARIES
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
// STYLES
import { DisplayHeadings } from "../../styles/type"
import { Breakpoints } from '../../styles/breakpoints'
// LANGUAGE
import { useContext } from 'react';
import Language from '../../content/language';
// COMPONENTS
import { ReactComponent as sign } from '../../assets/misc/404.svg'
import { ReactComponent as eye } from '../../assets/misc/eye-brow.svg'

const LostStyle = {
  Container: styled.div`
    width: 100vw;
    height:100vh;
    display:flex;
    justify-content:center;
    gap:5vh;
    align-items:center;
    flex-flow:column;
    padding:20vh 0 7vh;
    position:relative;
  `,
  Title: styled(DisplayHeadings.Display4)`
    color: ${(props)=>props.theme.misc.text};
    text-transform: uppercase;
    text-align: center;
    /* ///////// Responsive ///////// */ 
    ${Breakpoints.mobile} {font-size:5rem; padding:0 10%;}
    ${Breakpoints.medium} {letter-spacing:-0.05rem; padding:0 30%;}
    ${Breakpoints.tablet} {letter-spacing:-0.25rem; padding:0 30%;}
  `,
  Button: styled.button`
    color: ${(props)=>props.theme.misc.text};
    text-decoration: none;
    text-transform: uppercase;
    font-size:1.85rem;
    font-weight:700;
    letter-spacing:-0.05rem;
    background: none;
    border: solid 2px  ${(props)=>props.theme.misc.text};
    padding: 0.25rem 3rem;
    border-radius: 2rem;
    cursor:pointer;
    transition:all 200ms;
    &:active,
    &:hover {
      background: ${(props)=>props.theme.misc.button};
      border-color: ${(props)=>props.theme.misc.button};
      color:${(props)=>props.theme.background};
    }
  `
}

const Vectors = {
  Container: styled.div`
    position:relative;
  `,
  Number: styled(sign)`
    g {fill: ${(props)=>props.theme.misc.text};}
  `,
  LeftEye: styled(eye)`
    position: absolute;
    top: 30%;
    left:35%;
    /* transition: all 200ms; */
    g {fill: ${(props)=>props.theme.misc.eyes};}
  `,
  RightEye: styled(eye)`
    position:absolute;
    top:30%;
    right:35%;
    /* transition: all 200ms; */
    g {fill: ${(props)=>props.theme.misc.eyes};}
  `,
}

const LostComponent = () => {
  // Get Content
  const context = useContext(Language);
  const content = context.language.lost
  // Use navigation in order to go back to previous page
  const navigate = useNavigate();

  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)


  document.addEventListener('mousemove', event => {
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2
    const mouseX = event.pageX
    const mouseY = event.pageY
    const valueX = ((centerX - mouseX) * -1) / 25
    const valueY = ((centerY - mouseY) * -1) / 25

    setPosX(valueX )
    setPosY(valueY)

  })
  



return (
  <LostStyle.Container>
    <Vectors.Container>
      <Vectors.LeftEye className="eye" style={{transform: `translate(${posX}px,${posY}px)`}}/>
      <Vectors.RightEye className="eye" style={{transform: `translate(${posX}px,${posY}px)`}}/>
      <Vectors.Number />
    </Vectors.Container>
    <LostStyle.Title>{content.text}</LostStyle.Title>
    <LostStyle.Button onClick={() => navigate(-1)}>{content.button}</LostStyle.Button>
  </LostStyle.Container>
)}

export default LostComponent