// STYLES
import { ProjectStyle, ProjectLink } from './style'
import { Arrow } from '../../styles/vectors'
// CONTENT
import { Gallery, MobileGallery, ProjectLogos } from '../../assets/projects'
import ProjectList from '../../content/work.json'
// LANGUAGE
import { useContext } from 'react';
import Language from '../../content/language';
// COMPONENTS
import Footer from '../footer';

const ProjectLayout = (props) => {

  // Get project index
  const index = props.index
  
  /*  CONTENT  */
  /* ******************* */
  // Get Content
  const context = useContext(Language);
  const content = context.language.projects
  const link = context.language.link
  // Get Project content
  const projectContent = Object.values(content)[index]

  const ProjectSite = () => {
    const extLink = projectContent.external
    if(extLink) {
    return (<a href={extLink} target="_blank" rel="noreferrer" >
        {link}
        <Arrow />
      </a>)
    } else { return <a style={{display:'none'}} href={extLink}>n/a</a> }
  } 

  const ProjectInfos = () =>  (
    <ProjectStyle.Infos>
      <p>{projectContent.client} <span>({projectContent.type})</span></p>
    </ProjectStyle.Infos>
  )

  const ProjectText = projectContent.text 
  const ProjectTools = Object.values(projectContent.fullTags).map( tag => { return <p key={tag}>{tag}</p> })
  
  /*  IMAGES  */
  /* ******************* */
  // Get images for current project
  const images = Gallery[index]

  // Get Logo for current project
  const getLogo = () => {
         if ( index === 0 ) { return <ProjectLogos.Logo1 /> }
    else if ( index === 1 ) { return <ProjectLogos.Logo2 /> }
    else if ( index === 2 ) { return <ProjectLogos.Logo3 /> }
    else if ( index === 3 ) { return <ProjectLogos.Logo4 /> }
    else if ( index === 4 ) { return <ProjectLogos.Logo5 /> }
    else if ( index === 5 ) { return <ProjectLogos.Logo6 /> }
    else if ( index === 6 ) { return <ProjectLogos.Logo7 /> }
    else if ( index === 7 ) { return <ProjectLogos.Logo8 /> }
  }

  // Display all images for current project
  const getGallery = Object.values(images).map( (image, i) => {
    return   <ProjectStyle.Image src={image.src} key={i} alt={image.alt}/>
  })

  /*  MOBILE IMAGES  */
  /* ******************* */
  const ProjectMobile = () => {
    const getMobileImages = Object.values(MobileGallery)[index]
    const displayMobileGallery = Object.values(getMobileImages).map( (mobile, i) => {
        if(mobile) {  return (
            <ProjectStyle.MobileDevice key={i}>
            <ProjectStyle.MobileImage src={mobile.src} alt={mobile.alt}/>
            </ProjectStyle.MobileDevice>
          )}
        else { return }
      })

    return ( <ProjectStyle.Mobile> {displayMobileGallery} </ProjectStyle.Mobile> )
  }

  // Get name of project for aria-label
  const currentProject = Object.values(ProjectList)[index].name

  /*  NAVIGATION  */
  /* ******************* */
  // Create navigation links
  const ProjectNavigation = () => {

    const backLink = context.language.back

    // Get Previous & Next Projects
    const lastProject = Object.values(ProjectList).length - 1
    const previousIndex = index > 0 ? index - 1 : index
    const nextIndex = index >= lastProject ? index : index + 1
    const previousProject = Object.values(ProjectList)[previousIndex]
    const nextProject = Object.values(ProjectList)[nextIndex]
    // Get names for previous and next project -- if project is last, next name is 'playground'
    const previousName = index > 0 ? previousProject.name : ''
    let nextName = index >= lastProject ? 'Playground' : nextProject.name
    // Get paths for previous and next project -- if project is last, next path is 'playground'
    const lastPath = '/playground'
    const previousPath = previousProject.path
    const nextPath = index >= lastProject ? lastPath : nextProject.path
    // Hide link if current project is first (or last)
    const previousClass = previousIndex === index ? 'hide' : ''
    const nextClass = nextIndex === index ? '' : ''

    return (
      <ProjectStyle.Links>
        <ProjectLink to={previousPath} className={'page-link ' + previousClass} aria-label="previous project"> <Arrow /> {previousName} </ProjectLink>
        <ProjectLink to='/work' className='page-link'>{backLink}</ProjectLink>
        <ProjectLink to={nextPath} className={'page-link ' + nextClass} aria-label="next project"> {nextName} <Arrow/> </ProjectLink>
      </ProjectStyle.Links>
    )
  }


  /*  PROJECT PAGE - FULL RENDER */
  /* *************************** */
  return (
  <>
    <ProjectStyle.Container aria-label={currentProject}>
      <ProjectStyle.Header>
        <ProjectStyle.Logo className='logo'>
          {getLogo()}
        </ProjectStyle.Logo>
      </ProjectStyle.Header>
      <ProjectStyle.Content>
        <ProjectStyle.Gallery>
        <ProjectStyle.Details>
          <ProjectInfos />
          <ProjectStyle.Text>{ProjectText}</ProjectStyle.Text>
          <ProjectStyle.Tags>{ProjectTools}</ProjectStyle.Tags>
          <ProjectSite />
        </ProjectStyle.Details>
          <ProjectStyle.Images>
            {getGallery}
            <ProjectMobile />
          </ProjectStyle.Images>
        </ProjectStyle.Gallery>
      </ProjectStyle.Content>
      <ProjectNavigation />
    </ProjectStyle.Container>
    <Footer />
  </>
)}

export default ProjectLayout
