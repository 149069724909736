// LANGUAGE
import{ useContext } from 'react';
import Language from '../../content/language';
// LIBRARIES
// STYLES
import { HomeStyle, HomeBrand, HomeDetails, HomeMenu } from "./style";
import { HomeLinkStyle, LinkStyle, LinkArrow } from "./style";
// COMPONENTS


const MenuLink = (props) => (
  <HomeLinkStyle className='move-in'>
    <LinkStyle to={props.to} className='page-link'>{props.text}</LinkStyle>
    <LinkArrow />
  </HomeLinkStyle>
)


const HomeComponent = () => {
  // Get Content
  const context = useContext(Language);
  const content = context.language
  
  return (
  <HomeStyle.Container aria-label="homepage" name="homepage">
    <HomeStyle.Main>
      <HomeStyle.Brand>
        <HomeBrand.Container>
          <HomeBrand.HomeLogo className='move-in'/>
          <HomeBrand.Heading className='move-in'>maeva moissonnier</HomeBrand.Heading>
        </HomeBrand.Container>
        <HomeDetails.Container>
          <HomeDetails.Titles>
            <HomeDetails.Title className='move-in'> {content.home.title1}</HomeDetails.Title>
            <HomeDetails.Title className='move-in'>{content.home.title2}</HomeDetails.Title>
          </HomeDetails.Titles> 
        </HomeDetails.Container>
      </HomeStyle.Brand>
    <HomeStyle.Menu>
      <HomeMenu>
        <MenuLink to='/work' text={content.pages.work} />
        <MenuLink to='/about' text={content.pages.about} />
      </HomeMenu>
    </HomeStyle.Menu>
    </HomeStyle.Main>
    <HomeStyle.Footer aria-label="contacts" className='move-in'>
      <HomeStyle.Contact href='mailto:maeva.moissonnier@gmail.com' target='_blank' aria-label="email address">maeva.moissonnier@gmail.com</HomeStyle.Contact>
      <HomeStyle.Contact href='https://www.instagram.com/mae.mae.moi/' target='_blank' aria-label="instagram">instagram</HomeStyle.Contact>
      <HomeStyle.Contact href='https://www.linkedin.com/in/maevamoissonnier/' target='_blank' aria-label="linkedin">linkedin</HomeStyle.Contact>
    </HomeStyle.Footer>
  </HomeStyle.Container>
)}

export default HomeComponent