// LIBRARIES
import styled from 'styled-components'
// STYLES
import { Breakpoints } from '../../styles/breakpoints'
import { DisplayHeadings, Body } from '../../styles/type'
// COMPONENTS
import { Logo, Arrow } from '../../styles/vectors'
import { Link } from 'react-router-dom'

export const HomeStyle = {
  Container: styled.div`
    width: 100%;
    height: 100vh;
    display:flex;
    flex-wrap:wrap;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { padding: 3vh 0; }
    `
  ,
  Main: styled.div`
    display:flex;
    width: 100%;
    height:92%;
    align-items:center;
    padding-top:14vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow:column; padding-top:0; }
    ${Breakpoints.medium} { flex-flow:column; padding-top:0; height:95%; }
    ${Breakpoints.tablet} { flex-flow:column; padding-top:0; height:95%; }
` ,
  Brand:styled.div`
    flex:3;
    display:flex;
    flex-flow:column;
    gap:3vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { gap:2vh; justify-content: flex-end; width: 100%; }
    ${Breakpoints.medium} { justify-content: center; width: 100%; }
    ${Breakpoints.tablet} { justify-content: center; width: 100%; }
  `,
  Menu: styled.div`
    flex:1.25;
    padding-bottom:10vh;
    padding-right:2vw;
    display:flex;
    flex-flow:column;
    align-items: flex-end;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex:2.5; order:-1; padding-bottom:0; width: 100%; }
    ${Breakpoints.medium} { flex:2; align-items:flex-start; order:-1; padding-bottom:0; width: 100%; }
    ${Breakpoints.tablet} { order:-1; padding-bottom:0; width: 100%; }
  `,
  Footer: styled.div`
    width: 100%;
    height:8%;
    display:flex;
    justify-content:flex-end;
    align-items: center;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      height: max-content;
      padding-left:0;
      flex-wrap:wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 3vh 0 0;
    }
    ${Breakpoints.medium} {
      height: max-content;
      padding-left:0;
      flex-wrap:wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
    ${Breakpoints.tablet} {
      height: max-content;
      padding-left:0;
      flex-wrap:wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
    ${Breakpoints.smDesktop} {
      padding-left:25%;
      align-items: center;
    }
  `,
  Contact: styled.a`
    display:inline-block;
    text-decoration: none;
    color: ${(props) => props.theme.home.link};
    font-size:1rem;
    font-weight:500;
    width: 15%;
    text-align:right;
    transition: all 200ms;
    &:nth-of-type(1) {width: 75%;}
    &:hover,
    &:focus,
    &:active {color:${(props) => props.theme.home.linkHover}}
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      &:nth-of-type(1) {width: 100%;}
      text-align:left;
      font-size: 1rem;
      width: auto;
      margin-right:2rem;
      font-weight:400;
      line-height:1.5;
      color:${(props) => props.theme.home.contact}
    }
    ${Breakpoints.medium} { text-align:left; width: auto !important; }
    ${Breakpoints.tablet} { text-align:left; width: auto !important; }
  `
}

export const HomeBrand = {
  Container:styled.div`
    display:flex;
    flex-flow:column;
    gap:1rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow:column; align-items: flex-start; }
    ${Breakpoints.medium} { flex-flow:column; align-items: flex-start; }
    ${Breakpoints.tablet} { flex-flow:column; align-items: flex-start; }
  `,
  HomeLogo: styled(Logo)`
    width: 12vw;
    height:auto;
    g { fill: ${(props) => props.theme.home.brand}; }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { width:40vw; margin-left:0; }
    ${Breakpoints.medium} { margin-left:0.5rem; width:30vw; }
    ${Breakpoints.tablet} { margin-left:0.5rem; width:34vw; }
    ${Breakpoints.smDesktop} { margin-left:0.5rem; }
  `,
  Heading:styled(DisplayHeadings.Display5)`
    color: ${(props) => props.theme.home.brand};
    line-height:0.75;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:10vw; letter-spacing: -0.3vw; font-weight:600; }
    ${Breakpoints.medium} { font-size:7vw; letter-spacing: -0.15rem; }
    ${Breakpoints.tablet} { font-size:7vw; letter-spacing: -0.15rem; }
    ${Breakpoints.smDesktop} { letter-spacing: -0.15rem; }
  `
}

export const HomeDetails = {
  Container: styled.div`
    display:flex;
    flex-flow: column;
    gap:1rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow:column; gap:0.5rem; }
    ${Breakpoints.medium} { flex-flow:column; }
    ${Breakpoints.tablet} { flex-flow:column; }
  `,
  Titles: styled.div`
    display: flex;
    flex-flow: column;
    gap:0.2rem;
    color:${(props) => props.theme.home.text};
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { gap:0; }
  `,
  Title: styled.h1`
  font-size:1.5rem;
  text-transform: uppercase;
  font-weight:500;
  letter-spacing:-0.05rem;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { font-size: 1.25rem; }
  `
}

export const HomeMenu =  styled.div`
  display:flex;
  flex-flow:column;
  gap:2rem;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} {
    align-items: flex-end;
    gap:0.5rem;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }
  ${Breakpoints.medium} {
    align-items: flex-end;
    gap:0.5rem;
    justify-content: flex-end;
    height: 100%;
  }
  ${Breakpoints.tablet} {
    align-items: flex-end;
    gap:0.5rem;
    justify-content: flex-end;
    height: 100%;
  }
  ${Breakpoints.smDesktop} { align-items: flex-end; }
`
export const LinkStyle =  styled(Link)`
  font-size:4.5vw;
  color: ${(props) => props.theme.home.link};
  font-weight:700;
  text-transform: uppercase;
  text-decoration:none;
  letter-spacing:-0.2rem;
  transition:all 200ms;
  &:focus { color: ${(props) => props.theme.home.linkHover}; }
    /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { font-size:18vw; letter-spacing: -0.5vw; }
  ${Breakpoints.medium} { font-size:14vw; letter-spacing:-0.1rem; }
  ${Breakpoints.tablet} { font-size:6vw; letter-spacing:-0.1rem; }
  ${Breakpoints.smDesktop} { letter-spacing:-0.1rem; }
`
export const LinkArrow = styled(Arrow)`
  width:4vw;
  height:auto;
  g { transition:all 200ms; stroke:${(props) => props.theme.home.link}}
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { width: 8vw; }
  ${Breakpoints.medium} { width: 5vw; }
  ${Breakpoints.tablet} { width: 5vw; }
`
export const HomeLinkStyle = styled.div`
  display:flex;
  justify-content:space-between;
  gap:1rem;
  width: 100%;
  &:hover,
  &:active {
    ${LinkStyle} {color:${(props) => props.theme.home.linkHover};}
    ${LinkArrow} {g{stroke:${(props) => props.theme.home.linkHover};}}
  }
`