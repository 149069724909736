// LANGUAGE
import { useContext } from 'react';
import Language from '../../content/language';
// STYLES
import { AboutStyle, AboutHeading, IntroStyle, Ed, Co, Photo } from "./style"
import { Headings, Body } from "../../styles/type"
// COMPONENTS
import Footer from '../footer';

const Intro = () => {
  // Get Content
  const context = useContext(Language);
  const content = context.language.about.me
  
  return (
  <IntroStyle.Container className='move-in'>
    <AboutHeading>{content.heading}</AboutHeading>
    <IntroStyle.Block>
      <IntroStyle.Feature>{content.feature}</IntroStyle.Feature>
      <IntroStyle.Content>{content.text}</IntroStyle.Content>
    </IntroStyle.Block>
  </IntroStyle.Container>
)}

const Education = () => {

  // Get Content
  const context = useContext(Language);
  const content = context.language.about.edu

  return (
  <Ed.Container className='move-in'>
    <AboutHeading>{content.heading}</AboutHeading>
    <Ed.Content>
      <Ed.Schools>
        <Ed.Block>
          <Headings.Heading5>{content.one.year}</Headings.Heading5>
          <Headings.Heading6>{content.one.program}</Headings.Heading6>
          <Ed.School>{content.one.location}</Ed.School>
        </Ed.Block>
        <Ed.Block>
        <Headings.Heading5>{content.two.year}</Headings.Heading5>
          <Headings.Heading6>{content.two.program}</Headings.Heading6>
          <Ed.School>{content.two.location}</Ed.School>
        </Ed.Block>
      </Ed.Schools>
      <Ed.Skills>
        <Ed.Block>
          <Headings.Heading5>Web</Headings.Heading5>
          <Ed.Skill>HTML / CSS / Javascript / React / PHP / Bootstrap / Sass</Ed.Skill>
        </Ed.Block>
        <Ed.Block>
          <Headings.Heading5>Design</Headings.Heading5>
          <Ed.Skill>Photoshop / Illustrator / Indesign / After Effects / Figma</Ed.Skill>
        </Ed.Block>
      </Ed.Skills>
    </Ed.Content>
  </Ed.Container>
)}

const Country = (props) => (
  <Co.Country>
    <Body.Body400>{props.time}</Body.Body400>
    <Co.Line />
    <Body.Body400>{props.country}</Body.Body400>
  </Co.Country>
)

const Countries = () => {

  // Get Content
  const context = useContext(Language);
  const content = context.language.about.countries

  const getPlaces = Object.values(content.list).map( (item, i) => (<Country time={item.time} country={item.place} key={i} />))
  
  return (
  <Co.Container className='move-in'>
    <AboutHeading>{content.heading}</AboutHeading>
    <Co.Content>
      {getPlaces}
    </Co.Content>
  </Co.Container>
)}

const AboutComponent = () => (
  <>
    <AboutStyle>
        <Intro />
        <Education />
        <Countries />
    </AboutStyle>
    <Footer />
  </>
)

export default AboutComponent 