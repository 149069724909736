// LIBRARIES
import styled from 'styled-components'
// COMPONENTS
import { ReactComponent as LogoSVG } from '../assets/brand/logo.svg'
import { ReactComponent as ArrowSVG } from '../assets/brand/arrow.svg'

export const Logo = styled(LogoSVG)`
  g {
    fill:${props => props.color}; 
    transition: all 200ms;
  }
`
export const Arrow = styled(ArrowSVG)`
  g {
    stroke: ${props => props.color};
    transition: all 200ms;
  }
`