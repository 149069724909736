// LIBRARIES
import styled from 'styled-components'
// STYLES
import { DisplayHeadings, Headings, Body } from '../../styles/type'
import { Breakpoints } from '../../styles/breakpoints'
// COMPONENTS

export const AboutStyle = styled.div`
  margin:20vh 0 5vh;
  padding: 0 0 10vh;
  display:flex;
  flex-flow: column;
  gap:20vh;
  p { color: ${(props) => props.theme.about.content};}
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { margin:0; padding:0 0 10vh; gap:3vh; }
  ${Breakpoints.medium} { margin:0; padding:0 0 10vh; gap:3vh; }
  ${Breakpoints.tablet} { padding:15vh 0 5vh; gap:5vh; }
  ${Breakpoints.smDesktop} { gap:5vh; }
  `
export const AboutHeading = styled(DisplayHeadings.Display3)`
  color:${(props) => props.theme.about.heading};
  text-transform: capitalize;
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} { font-size:13vw; letter-spacing: -0.5vw; }
  ${Breakpoints.medium} { font-size:13vw; letter-spacing: -0.25rem; }
  ${Breakpoints.tablet} { font-size:10vw; letter-spacing: -0.25rem; }
`
export const IntroStyle = {
  Container:styled.div`
    padding-top:15vh;
    height: 80vh;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { height: initial; padding-top: 15vh; }
    ${Breakpoints.medium} { height: initial; padding-top: 20vh; }
    ${Breakpoints.tablet} { height: initial; padding-top:0;}
  `,
  Block: styled.div`
    display:flex;
    gap:7vw;
    margin:5vh 0;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {flex-flow: column;}
    ${Breakpoints.medium} {flex-flow: column;}
    ${Breakpoints.tablet} {flex-flow: column; gap:2vh;}
    ${Breakpoints.smDesktop} { gap:3vw; }
  `,
  Feature: styled(Body.Body700)`
    flex:1.25;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:1.5rem; font-weight:600; line-height: 1.15;}
    ${Breakpoints.smDesktop} { flex:1.5;}
  `,
  Content: styled(Body.Body500)`
    flex:1;
    color:${(props) => props.theme.about.sub_content} !important;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {font-size:0.85rem;}
    ${Breakpoints.smDesktop} {font-size:1rem;}
  `
}

export const Ed = {
  Container: styled.div`
    display:flex;
    flex-flow:column;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {gap:0;}
  `,
  Content: styled.div`
    display:flex;
    gap:6vw;
    margin:5vh 0 0;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow: column; gap:5vh; margin:1.5rem 0 0;}
    ${Breakpoints.medium} { flex-flow: column; gap:3vh; margin:1.5rem 0 0;}
    ${Breakpoints.tablet} { flex-flow: column; gap:3vh; margin: 3vh 0 0;}
    ${Breakpoints.smDesktop} {gap:3vw;}
  `,
  Schools:styled.div`
    flex:1.25;
    display:flex;
    flex-flow:column;
    gap:5vh;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex:1; gap:0.5rem;
      ${Headings.Heading5} {font-size:2.25rem;}
      ${Headings.Heading6} {font-size:1.4rem; font-weight:700; margin: 0 0 0.25rem;}
      ${Body.Body500} {font-size:1rem; line-height:1.15; letter-spacing:-0.02rem;}
    }
    ${Breakpoints.medium} { flex:1; gap:1rem;
      ${Headings.Heading6} {font-size:1.6rem; font-weight:600;}
      ${Body.Body500} {font-size:1rem; line-height:1.15; letter-spacing:-0.02rem;}
    }
    ${Breakpoints.smDesktop} {flex:1.5;}
  `,
  School:styled(Body.Body300)`
    letter-spacing: 0;
    color:${(props) => props.theme.about.sub_content} !important;
      /* ///////// Responsive /////////   */ 
      ${Breakpoints.mobile} { font-size: 0.8rem; line-height:1.25;}
  `,
  Skills: styled.div`
    flex:1;
    display:flex;
    flex-flow:column;
    gap:5vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { gap:1vh;
      ${Headings.Heading6} {font-size:2.5rem} }
    ${Breakpoints.medium} { 
      ${Headings.Heading6} {font-size:2.5rem} }
    ${Breakpoints.tablet} { gap:1vh; }
    ${Breakpoints.smDesktop} { 
      ${Headings.Heading6} {font-size:2.5rem} }
  `,
  Block:styled.div`
    display:flex;
    flex-flow:column;
    height:10vh; 
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      ${Headings.Heading5} {font-size:2.25rem;}
      height: initial;
    }
    ${Breakpoints.medium} { height: initial;}
    ${Breakpoints.tablet} { height: initial;}
    ${Breakpoints.smDesktop} { justify-content:space-between; }
  `,
  Skill:styled(Body.Body500)`
    text-transform: uppercase;
    line-height:2;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { line-height:1.5; font-size: 1rem; }
    ${Breakpoints.medium} { line-height:1.5; }
    ${Breakpoints.smDesktop} { font-size:1.15rem;line-height:1.25 !important; }
  `
}

export const Co = {
  Container: styled.div`
    display:flex;
    flex-flow:column;
  `,
  Content: styled.div`
    margin:1.5rem 0 0;
    width: 60%;
    display: flex;
    flex-flow:column;
    gap:0.5rem;
  /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      width: 100%;
      gap:1.25rem;
      ${Body.Body500} {font-size:1rem;} }
    ${Breakpoints.medium} {
      width: 100%;
      ${Body.Body500} {font-size:1rem;} }
    ${Breakpoints.tablet} { width: 100%; margin: 3vh 0 0; }
    ${Breakpoints.smDesktop} {width: 75%;}
  `,
  Country: styled.div`
    display:flex;
    align-items:center;
    gap:1rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      align-items:flex-end;
      flex-wrap:wrap;
      gap:0.1rem;
      p:first-of-type { width: 100%; line-height:0.75;}
      p:last-of-type { padding-left: 0.5rem; line-height:0.75;}
    }
  `,
  Line: styled.div`
    flex-grow:1;
    height:2px;
    border-top:dotted 2px ${(props) => props.theme.about.content};
  `
}

