// LIBRARIES
import styled from 'styled-components'
// STYLES
import { Breakpoints } from '../../styles/breakpoints'

export const  FooterStyle = styled.div`
  padding:1rem 0;
  font-size:0.75rem;
  letter-spacing:-0.025rem;
  opacity:0.85;
  color:${(props) => props.theme.content};
  /* display:none; */
`