// LIBRARIES
import { useState, useEffect, useCallback, useRef } from "react";
// Themes
import { ThemeProvider } from "styled-components";
import { Themes } from "./styles/colors";
// Content
import content from './content/content.json'
import Language from "./content/language";
// STYLES
import './fonts/avantt/stylesheet.css'
import { GlobalStyle, AppComponent } from "./styles/globalStyles";
// COMPONENTS
import Nav from "./components/nav";
// Routes
import Routing from "./routes/router";
// Other
import './styles/transitions'

const App = () => {

/* LANGUAGE */
  // English version of the content
  const EN = content.EN
  // French version of the content
  const FR = content.FR
  // State for the language
  const [ language, setLanguage ] = useState(EN)

  // Context Values for Language Provider
  const LanguageValue = {
    EN, FR, language, setLanguage
  }

/* COLOR THEME */
  // State for the color theme
  // default state is Red
  const [ColorTheme, setColorTheme] = useState(Themes.Red);
  // callback for the <Mode> component
  const callBack = useCallback((ColorTheme) => {
    setColorTheme(ColorTheme);
  }, []);

  return (
    <ThemeProvider theme={ColorTheme}>
    <Language.Provider value={LanguageValue}>
      <AppComponent>
        <GlobalStyle />
        <Nav changeTheme={callBack} ColorTheme={ColorTheme} setColorTheme={setColorTheme}/>
        <Routing />
      </AppComponent>
    </Language.Provider>
    </ThemeProvider>
  )}

  
export default App;
