// LIBRARIES
import styled from 'styled-components'
// STYLES
import { DisplayHeadings } from '../../../styles/type'
import { Breakpoints } from '../../../styles/breakpoints'
// COMPONENTS
import { Link } from "react-router-dom";

export const ProjectLinkStyle = {
  Container: styled(Link)`
    width: max-content;
    display:flex;
    flex-flow: row;
    text-decoration:none;
    align-items:flex-end;
    gap:1.25rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow:column-reverse; align-items: flex-start; gap:0.5rem; width: initial;}
    ${Breakpoints.medium} { flex-flow:column; align-items: flex-start; gap:0.5rem; width: initial;}
    ${Breakpoints.tablet} { flex-flow:column; align-items: flex-start; gap:0; width: initial;}
  `,
  Name: styled(DisplayHeadings.Display2)`
    display:inline-block;
    color: ${(props) => props.theme.work.project};
    transition: all 200ms;
    line-height:0.8;
    &:hover,
    &:active {color: ${(props) => props.theme.work.projectHover};}
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      font-size:14vw;
      letter-spacing: -0.7vw;
      width: 100%;
      word-wrap: break-word;
      line-height:0.2;
    }
    ${Breakpoints.medium} {
      font-size:13vw;
      letter-spacing: -0.35rem;
      width: 100%;
      word-wrap: break-word;
    }
    ${Breakpoints.tablet} { font-size:13vw; }
    ${Breakpoints.wide} { font-size:12vw; letter-spacing:-0.75vw; }
  `,
  Infos: styled.div`
    color: ${(props) => props.theme.work.projectInfos};
    display:flex;
    gap:0.75rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { align-items:center; opacity:0.9;}
    ${Breakpoints.medium} { align-items:flex-end; }
  `,
  Number: styled.div`
    font-weight:600;
    font-size: 2.5rem;
    letter-spacing: -0.1rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:1.5rem; }
    ${Breakpoints.medium} { font-size:2rem }
  `,
  Details: styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-weight:500;
    letter-spacing: -0.02rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.medium} { flex-flow: row; gap:1rem; padding-bottom:0.25rem;}
  `,
  Tags:styled.p`
    text-transform: uppercase;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { font-size:0.79rem; letter-spacing:-0.05vw;font-weight:600; }
    `, 
  Client:styled.p`
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { display:none; }
  `
}

