// TOOLS / LIBRARIES
// CONTENT
import PlayContent from '../../../content/playground.json'
// STYLES
import { ProjectPage } from "../../../pages/project"
import { ProjectStyle } from "../style"
import { PlaygroundStyle, PlaygroundInfo, PlaygroundGallery } from "./style"
// COMPONENTS
import { ProjectLogos } from "../../../assets/projects"
// LANGUAGE
import { useContext } from "react"
import Language from "../../../content/language"
// IMAGES
import { Arrow } from "../../../styles/vectors"
import { PlayGallery } from "../../../assets/projects"

const Playground = () => {
  // Content
  const context = useContext(Language);
  const content = context.language
  const externalLink = content.link

  // Get the index of the project, add 1, and make singles into double digits
  const index = (x) => {
    if (x < 10) { return "0" + (x + 1) + "/" }
    else { return x + "/" }
  }

  // create a component for the images, mapping the content, and containing all data for info on the left side, to change according to the image showing
  const PlaygroundBlock = Object.values(PlayContent).map((play, i) => {

    const source = PlayGallery[i]

    return (
      <PlaygroundStyle.Image key={i} className="reveal" src={source} data-title={play.title} data-number={index(i)} data-link={play.link} data-index={i}/>)
  })

  return (
    <ProjectPage>
      <ProjectStyle.Container>
        <ProjectStyle.Header>
          <ProjectStyle.Logo className="logo"> <ProjectLogos.Logo8/> </ProjectStyle.Logo>
        </ProjectStyle.Header>
        <PlaygroundGallery>
          <PlaygroundInfo>
            <PlaygroundStyle.Number className="playground-number"></PlaygroundStyle.Number>
            <PlaygroundStyle.Title className="playground-title"></PlaygroundStyle.Title>
            <PlaygroundStyle.Tools className="playground-tools"></PlaygroundStyle.Tools>
            <PlaygroundStyle.Link target="_blank" href="" className="playground-link" > {externalLink} <Arrow/> </PlaygroundStyle.Link>
          </PlaygroundInfo>
          <PlaygroundStyle.Container className="playground container"> {PlaygroundBlock} </PlaygroundStyle.Container>
        </PlaygroundGallery>
      </ProjectStyle.Container>
    </ProjectPage>
  )
}

export default Playground
