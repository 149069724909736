import styled from 'styled-components'

// Display headings
export const DisplayHeadings = {
  Display1 : styled.h1`
    font-size:12vw;
    letter-spacing:-0.5rem;
    line-height:1;`,
  Display2 : styled.h2`
    font-size:10vw;
    letter-spacing:-0.5rem;
    line-height:1;`,
  Display3 : styled.h3`
    font-size:7vw;
    letter-spacing:-0.4rem;
    line-height:1.25;`,
  Display4 : styled.h4`
    font-size:5vw;
    letter-spacing:-0.4rem;
    line-height:1.25;`,
  Display5 : styled.h5`
    font-size:4vw;
    letter-spacing:-0.3rem;
    line-height:1.25;`,
}

// Standard headings
export const Headings = {
  Heading1 : styled.h1`
    font-size:4rem;
    letter-spacing:-0.25rem;`,
  Heading2 : styled.h2`
    font-size:3.5rem;
    letter-spacing:-0.25rem;`,
  Heading3 : styled.h3`
    font-size:3rem;
    letter-spacing:-0.2rem;
    line-height:1;`,
  Heading4 : styled.h4`
    font-size:2.75rem;
    letter-spacing:-0.2rem;`,
  Heading5 : styled.h5`
    font-size:2.5rem;
    letter-spacing:-0.15rem;`,
  Heading6 : styled.h6`
    font-size:1.75rem;
    letter-spacing:-0.05rem;
    font-weight:700;`,
}

// Body
export const Body = {
  Body700 : styled.p`
    font-size:2rem;
    line-height:1.2;
    font-weight:700;
    letter-spacing:-0.075rem;`,
  Body600 : styled.p`
    font-size:1.5rem;
    line-height:1.3;
    font-weight:500;`,
  Body500 : styled.p`
    font-size:1.2rem;
    line-height:1.4;
    font-weight:500;`,
  Body400 : styled.p`
    font-size:1.05rem;
    font-weight:500;
    line-height:1.35;`,
  Body300 : styled.p`
    font-size:0.95rem;
    font-weight:500;
    letter-spacing:0.015rem;
    line-height:1.35;`,
}