// LIBRARY
import styled from 'styled-components'
// COMPONENTS
import Nav from '../components/nav'
import AboutComponent from '../components/about'

const Aboutpage = styled.section``


const About = () => (
  <Aboutpage>
    <AboutComponent />
  </Aboutpage>
)

export default About