// LIBRARY
import styled from 'styled-components'
// COMPONENTS
import LostComponent from "../components/misc/404"

const LostPage = styled.section`
  margin:0;
  padding:0;
`


const Lost = () => (
  <LostPage>
    <LostComponent />
  </LostPage>
)

export default Lost