// LANGUAGE
import { useContext } from 'react';
import Language from '../../content/language';
// COMPONENTS
import { FooterStyle } from './style';

const Footer = () => {
  // Get Content
  const context = useContext(Language);
  const content = context.language.footer
  
  // Get year
  const getDate = new Date()
  const getYear = getDate.getFullYear()

  return (
  <FooterStyle>{content}. &copy; {getYear} maeva moissonnier </FooterStyle>
)}

export default Footer