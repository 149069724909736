// LIBRARIES
import styled from 'styled-components'
// STYLES
import { Breakpoints } from '../../styles/breakpoints'


export const WorkStyle = {
  Container: styled.div`
    margin: 15vh 0 0;
    padding: 0 0 15vh;
    display:flex;
    flex-flow: column;
    gap:5vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} {
      margin:0;
      padding: 10vh 0 6vh;
      justify-content: space-between;
      gap:5vh;
      height: initial;
    }
    ${Breakpoints.medium} {
      margin:0;
      padding: 10vh 0 6vh;
      justify-content: space-between;
      gap:4vh;
      height: initial;
    }
    ${Breakpoints.tablet} {
      margin:15vh 0 0;
      padding:0 0 15vh;
      gap:4vh;
    }
  `
}

export const Thumbnail = {
  Container: styled.div`
    height: 32vh;
    width:24vw;
    position:absolute;
    opacity:0;
    pointer-events: none;
    transform: translate(20%,-100%) scale(0);
    transform-origin:center center;
    transition: all 300ms ease-out;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.tablet} {display: none}
    ${Breakpoints.smDesktop} { height: 30vh; width: 42vh; }
  `,
  Image: styled.img`
    width:100%;
    height: 100%;
    object-fit: cover;
  `,
  Content: styled.p`
    display:inline-block;
    font-size:1rem;
    font-weight:500;
    letter-spacing:0.025rem;
    color:${(props)=> props.theme.work.projectHover};
    right:-5%;
    top:-6%;
    position:absolute;
    z-index:20;
    text-transform: uppercase;
  `
}