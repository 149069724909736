/* ********* IMPORTS ********** */
import { Thumbnails } from "../assets/projects"
import PlayContent from '../content/playground.json'
import { Breaks } from '../styles/breakpoints'

/* *************************************** */
/* ********* GLOBAL TRANSITIONS ********** */
export const globalTransitions = () => {
  /* Fade in content */
  const fadeIn_content = () => {
    const allPageLinks = document.querySelectorAll('.page-link')
    allPageLinks.forEach( pageLink => {
      const nav = document.querySelector('nav')
      const section = document.querySelector('section')
      pageLink.addEventListener('click', event => {
        section.style.opacity = 0
        nav.style.opacity = 0
      })
    })
  }

  /* Move in content */
  const moveIn_content = () => {
    const allElements = document.querySelectorAll('.move-in')
    allElements.forEach( (element, i) => {
      // fade-in and move-in elements
      const delay = (i * 200) + 'ms'
      element.style.transform = 'translateY(0)'
      element.style.transitionDelay = delay
      element.style.opacity = 1
    })
  }
  fadeIn_content()
  moveIn_content()
}


/* ****************************** */
/* ********* WORK PAGE ********** */
/* Make thumbnail appear on hover */
export const showThumbnail = (location) => {
  // Get all thumbnails
  const allThumbnails = Object.values(Thumbnails)
  const page = location.pathname

  if( page === '/work' && window.innerWidth > 800 ) {

    const thumbnail = document.querySelector('.thumbnail')
    const thumbnailImage = thumbnail.querySelector('img')
    const allProjects = document.querySelectorAll('.project-link')
    // Position thumbnail according to position of mouse
    document.addEventListener('mousemove', event => {
      const posX = event.pageX
      const posY = event.pageY 
      thumbnail.style.top = posY  + 'px'
      thumbnail.style.left = posX + 'px'
    })
    // Reveal image when hovering on project link and get the project thumbnail src
    allProjects.forEach( (project, i) => {
      project.addEventListener('mouseover', event => {
        thumbnail.style.transform = 'translate(20%,-100%) scale(1)'
        thumbnail.style.opacity = 1;
        const source = allThumbnails[i]
        thumbnailImage.src = source
      })
      project.addEventListener('mouseout', event => {
        thumbnail.style.transform = 'translate(20%,-100%) scale(0)'
        thumbnail.style.opacity = 0;
      })
    })
  }
}

/* ****************************** */
/* ******** PROJECT PAGE ******** */
/* Make images appear when in view */
export const reveal = () => {
  // Get all images
  const AllImages = document.querySelectorAll('img')

  // Fade in images when in view (scale + opacity)
  const fadeIn = (elements) => {
    elements.forEach(element => {

      const top = element.getBoundingClientRect().top + 200

      if (top < window.innerHeight) {
        element.style.opacity = 1
        element.style.transform = 'scale(1)' 
      } 

      else {
        element.style.opacity = 0
        element.style.transform = 'scale(0.9)' 
      };
    });
  };
  // Trigger Fade in function when scrolling
  document.addEventListener("scroll", function () {
    fadeIn(AllImages);
  });

}
/* Make project page logo fade when scrolling down */
export const fadeLogo = (location) => {
  const page = location.pathname

  if ( page !== '/' && page !== '/work' && page !== '/about') {
    const projectLogo = document.querySelector('.logo')
    // Set viewport height depending on device (mobile or desktop)
    let viewport = ''
    if( window.innerWidth > Breaks.tablet ) { viewport = window.innerHeight }
    if( window.innerWidth <= Breaks.tablet ) { viewport = 300 }
    // When scrolling past a third of the viewport, trigger fade in or out
    document.addEventListener("scroll", () => {
      const scrollPos = window.scrollY + (viewport / 3)
      if( scrollPos > viewport ) { projectLogo.style.opacity = 0 }
      else { projectLogo.style.opacity = 1 }
    })
  }
}

/* ****************************** */
/* ******* PLAYGROUND PAGE ****** */
/* Get infos for each project when the come into view */
const getPlayProjects = () => {
  // Get all images on the page
  const playImages = document.querySelectorAll('img')
  
  // Get DOM elements filled with content
  const number = document.querySelector('.playground-number')
  const title = document.querySelector('.playground-title')
  const tools = document.querySelector('.playground-tools')
  const playLink = document.querySelector('.playground-link')
  // Get infos relevant to current project while scrolling
  const getInfos = () => {
    playImages.forEach( playImg => {
      // Get all scrolling data to trigger change of content
      const imgTop = playImg.getBoundingClientRect().top
      const imgBottom = playImg.getBoundingClientRect().bottom
      let screenTop = (window.innerHeight / 2) + 200
      const screenBottom = window.innerHeight - (window.innerHeight / 2)
      // For mobile deviced
      const mobileWidth = Breaks.mobile
      if(window.innerWidth <= mobileWidth) { screenTop = window.innerHeight / 2 }
      // Get all attributes of current project to fill project infos
      const numberAttr = playImg.getAttribute('data-number')
      const titleAttr = playImg.getAttribute('data-title')
      const linkAttr = playImg.getAttribute('data-link')
      const indexAttr = playImg.getAttribute('data-index')

      const currentPlay = PlayContent[indexAttr]
      const currentTools = currentPlay.tools
      // Insert info from attributes into their respective DOM component
      if( imgTop < screenBottom && imgBottom > screenTop) {
        number.innerHTML = numberAttr
        title.innerHTML = titleAttr
        playLink.setAttribute('href', linkAttr)
        tools.innerHTML = Object.values(currentTools).map( tool => (`<p>${tool}</p>`)).join('') }
      })
  }

  // Trigger on scroll
  document.addEventListener( 'scroll', e=> getInfos())

}
/* Trigger function when on Playground page */
export const getPlay = (location) => {
  const page = location.pathname
  if ( page === '/playground' ) { getPlayProjects() }
}

/* ****************************** */
/* ******* LOADING IMAGES ****** */
// Use lazy loading for images
export const setLazy = () => {
  const siteImages = document.querySelectorAll('img')
  siteImages.forEach( img => {
    // img.setAttribute('loading', 'lazy')
    img.setAttribute('rel', 'preload')
    img.setAttribute('as', 'image')
  })
}