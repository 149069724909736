// LIBRARY#
import styled from 'styled-components'
// COMPONENTS
import ProjectLayout from '../components/project'


export const ProjectPage = styled.section`
  /* height:100vh; */
`

const Project = (props) => (
  <ProjectPage>
    <ProjectLayout  name={props.name} path={props.path} index={props.index}/>
  </ProjectPage>
)

export default Project