// TOOLS
// STYLES
import styled from 'styled-components'
import { Breakpoints } from '../../styles/breakpoints'
// COMPONENTS
import { Link } from 'react-router-dom'
import { Arrow } from '../../styles/vectors'

export const ProjectStyle = {
  Container: styled.div`
    position:relative;
  `,
  Header: styled.div`
    height:95vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.tabletPortrait} {height:90vh;}
  `,
  Infos:styled.div`
    width: 100%;
    margin:1.5vh 0 6vh;
    padding:0 0 0.5rem;
    font-weight:500;
    transition: all 300ms;
    p {
      font-size:1.2rem;
      text-transform: uppercase;
      letter-spacing:-0.025rem;
      color: ${(props)=> props.theme.project.text};
    }
    span {
      text-transform: none;
      font-size:0.9rem;
      margin-left:0.75rem;
    }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { margin:1.5vh 0 2vh; }
    ${Breakpoints.medium} { margin:1.5vh 0 2vh; }
    ${Breakpoints.tablet} { margin:1.5vh 0 2vh; }
    ${Breakpoints.smDesktop} { margin: 0 0 6vh;
      p { display: flex; flex-flow: column; }
      span { margin:0; }}
  `,
  Logo: styled.div`
    width: 100vw;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    top:0;
    left:0;
    transition: opacity 0.75s;
    svg {
      width: 75vw;
      height: auto;
    }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { svg { width: 90vw; }}
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    flex-flow:column;
    z-index: 5;
    padding:0 0 0;
    position: relative;
    gap:10vh;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { padding:0; gap:5vh; }
    ${Breakpoints.medium} { padding:0; gap:5vh; }
    ${Breakpoints.tablet} { padding:0; gap:5vh; }
  `,
  Gallery:styled.div`
    flex: 4;
    display:flex;
    align-items:flex-start;
    gap:3rem;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow: column; }
    ${Breakpoints.medium} { flex-flow: column; }
    ${Breakpoints.tablet} { flex-flow: column; }
    ${Breakpoints.smDesktop} { gap:3rem; }
  `,
  Details:styled.div`
    flex:1.25;
    position:sticky;
    top:20vh;
    a {
      display:flex;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      font-size:1.25rem;
      font-weight:500;
      line-height:0.75;
      text-transform: uppercase;
      letter-spacing:-0.05rem;
      text-decoration:none;
      color: ${(props)=> props.theme.project.hover};
      transition: all 200ms;
      margin:5rem 0 0;
      ${Arrow} {
      g {stroke: ${(props)=>props.theme.project.hover}}
      margin-left:0.5rem;
      transition: all 200ms;
      width: 1rem;
      }
      &:hover {
      color: ${(props)=> props.theme.project.hover};
        ${Arrow} {
        g {stroke: ${(props)=>props.theme.project.hover}};
        transform: translateX(0.5rem);
        }
      }}
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { position:relative; top: initial; }
    ${Breakpoints.medium} { position:relative; top: initial; }
    ${Breakpoints.tablet} { position:relative; top: initial; }
    ${Breakpoints.smDesktop} { flex:1.5; }
  `,
  Text:styled.p`
    margin:0 3rem 3rem 0;
    font-size:0.9rem;
    line-height:1.25;
    letter-spacing: -0.025rem;
    font-weight:500;
    white-space: pre-wrap;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { margin: 0 0 2rem; }
    ${Breakpoints.medium} { margin: 0 0 2rem; }
    ${Breakpoints.tablet} { margin: 0 0 2rem; font-size:0.9rem; }
    ${Breakpoints.smDesktop} { margin: 0 0 2rem; }
  `,
  Tags: styled.div`
    text-transform: uppercase;
    font-weight:600;
    letter-spacing: -0.02rem;
    font-size:0.95rem;
    line-height:1.25;
    /* ///////// Responsive /////////   */ 
    /* ${Breakpoints.mobile} {display:flex; gap:1rem;} */
    ${Breakpoints.medium} {display:flex; gap:1rem;}
    ${Breakpoints.tablet} {display:flex; gap:1rem;}
  `,
  Images:styled.div`
    flex:4;
    display:flex;
    flex-flow:column;
    gap:1.5rem;
    margin:5vh 0 0;
  `,
  Image: styled.img`
    width: 100%;
    height:auto;
  `,
  Mobile: styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin:5vh 0 0 5vw;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { flex-flow:column; gap:2vh;}
  `,
  MobileDevice: styled.div`
    width: 25%;
    height:auto;
    border-radius: 0.5vw;
    overflow:hidden;
    &:nth-of-type(1){ margin-top: 15vh; }
    &:nth-of-type(3){ margin-top: 15vh; }
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { width: 60%; margin-top: 0 !important; }
    ${Breakpoints.medium} { width:30%; }
    ${Breakpoints.tablet} { width:30%; }
    ${Breakpoints.smDesktop} { width:30%; }
  `,
  MobileImage: styled.img`
    width: 100%;
    height:100%;
    display: block;
  `,
  Links: styled.div`
    width: 100%;
    padding:8vh 0 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:relative;
    z-index:5;
    /* ///////// Responsive /////////   */ 
    ${Breakpoints.mobile} { height:10vh; }
  `
}

export const ProjectLink = styled(Link)`
  font-size:1.1rem;
  color:${(props)=> props.theme.project.link};
  font-weight:500;
  text-decoration:none;
  text-transform: uppercase;
  transition: all 200ms;
  display:flex;
  align-items:center;
  gap:0.5rem;
  ${Arrow} {
    width: 1.1rem;
    transition: all 200ms;
    g {stroke:${(props)=> props.theme.project.link}; stroke-width: 2px;}
    height: 100%;
  }
  &:first-of-type { ${Arrow} {transform: scale(-1)}}
  &:hover {
    color:${(props)=> props.theme.project.linkHover};
    ${Arrow} {
    g {stroke:${(props)=> props.theme.project.linkHover}; }}
    &:first-of-type { ${Arrow} { transform: scale(-1) translateX(0.75rem) }}
    &:last-of-type { ${Arrow} { transform: translateX(0.75rem) }}
  }
  &.hide {
    opacity:0;
    pointer-events: none;
  }
  /* ///////// Responsive /////////   */ 
  ${Breakpoints.mobile} {
    font-size:0.85rem;
    font-weight:600;
    letter-spacing:-0.1vw;
    &:nth-of-type(2) { text-transform: lowercase; }
    &.hide { display:none; }
  }
`

