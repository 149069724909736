export const Breaks = {
  mobile: "600",
  medium: "800",
  tablet: "1100",
  smDesktop: "1500",
  desktop: "1920"
}

export const Breakpoints = {
  mobile: `@media only screen and (max-width: ${Breaks.mobile}px)`,
  medium: `@media only screen and (min-width:${Breaks.mobile}px) and (max-width: ${Breaks.medium}px)`,
  tablet: `@media only screen and (min-width:${Breaks.medium}px) and (max-width: ${Breaks.tablet}px)`,
  tabletPortrait: `@media only screen and (min-width:${Breaks.medium}px) and (max-width: ${Breaks.tablet}px) and (orientation: portrait)`,
  smDesktop: `@media only screen and (min-width:${Breaks.tablet}px) and (max-width: ${Breaks.smDesktop}px)`,
  desktop: `@media only screen and (min-width:${Breaks.smDesktop}px) and (max-width: ${Breaks.desktop}px)`,
  wide: `@media only screen and (min-width:${Breaks.desktop}px)`
}

export const Pad =  {

  mobile:"4vw",
  medium:"4vw",
  tablet:"3vw",
  smDesktop:"3vw",
  desktop:"3vw",
  wide:"3vw"

}


